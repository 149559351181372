html,
body,
#root,
#app > div {
  height: 100%;
  background-color: white;
}

body {
  margin: 0px;
}
.wizard-nav-area {
  background-color: aqua;
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.pn-div {
  min-width: 5rem;
  display: flex;
  justify-content: center;
}
.steps-div {
  padding: 0rem 2rem;
}
.invisible {
  opacity: 0;
}

.step-area {
  background-color: goldenrod;
  flex-grow: 2;
}
.photo-grid-area {
  background-color: yellow;
  min-height: 4rem;
}
.drag-drop-container {
  background-color: pink;
  min-height: 5rem;
  min-width: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.drag-drop-area {
  background-color: teal;
  min-height: 16rem;
  min-width: 30rem;
  max-width: 30rem;
  border-color: aliceblue;
  border-width: 0.2rem;
  border-style: dashed;
  border-radius: 1rem;
}
/*----*/
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-watermark {
  font-family: 'Abril Fatface', serif;
  background-color: black;
  color: white;
  font-size: larger;
  /*text-shadow: 3px 3px 3px #aaa;*/
}

.logo-here {
  font-family: 'Abril Fatface', sans;
  background-color: #f7e73a;
  color: black;
  font-size: larger;
  /*text-shadow: 3px 3px 3px #aaa;*/
}

.w-yellow-bg {
  background-color: #f7e73a;
}

/*.props-area {
    height : 460px !important;
}*/
/*workaround*/
@media screen and (max-height: 700px) {
  .props-area {
    height: 450px !important;
  }
}

@media screen and (max-width: 1024px) {
  .wizard-title {
    font-size: 0.8rem !important;
  }
  .download-tab {
    width: 45% !important;
  }
  .saved-watermark-button {
    width: 50% !important;
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
  min-height: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #6b7280;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  min-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4b5563;
}

.ReactModal__Content {
  overflow: hidden !important;
}
